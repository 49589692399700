import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from 'react';
import classNames from 'classnames';
import {
  Typography,
  TextareaAutosize,
  Container,
  Box,
} from '@material-ui/core';
import { ActionButton } from 'components';
import { AntTab, AntTabs } from 'components/Tabs';
import { CodeBlock } from 'components/CodeBlock';

import { currentModelState, codeState } from 'store/models';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  EXPERIMENT_TASK,
  ExperimentLabel,
  ExperimentTest,
  Experiment,
} from 'types';
import { ClassificationResponseBox } from './ResponseBox/ClassificationResponseBox';
import { TranslationResponseBox } from './ResponseBox/TranslationResponseBox';
import { NerResponseBox } from './ResponseBox/NerResponseBox';
import { Alert } from '@material-ui/lab';
import { QandAResponseBox } from './ResponseBox/QandAResponseBox/QandAResponseBox';
import {
  useAuthenticationContext,
  usePredictionContext,
  useUserPlanContext,
  useApplicationContext,
} from 'context';

import { FetchError } from 'utils/errors';
import useStyles from './texttest.styles';
import { TextCustomExtractor } from './ResponseBox/TextCustomExtractor';
import { DEFAULT_VALUES } from 'pages/Application/Application';
import { LLMClassificationResponseBox } from './ResponseBox/LLMClassificationResponseBox/LLMClassificationResponseBox';

interface Props extends ExperimentTest {
  modelId: string;
  task: number;
  title?: string;
  isApplication?: boolean;
  experiment?: Experiment;
}

type ResultPanel = { [key in string | number]: ReactNode };

const predictContentKey: ExperimentLabel = {
  [EXPERIMENT_TASK.TEXT_QANDA]: 'question',
};

export const TextTest = React.memo(
  ({
    task,
    title,
    subtitle = null,
    submitText = null,
    application,
    isApplication = false,
  }: Props) => {
    const { user, userApiKey } = useAuthenticationContext();
    const { application: applicationState } = useApplicationContext();
    const classes = useStyles({
      colorBox:
        applicationState?.config?.colorBox || DEFAULT_VALUES['colorBox'],
    });
    const currentModel = useRecoilValue(currentModelState);
    const {
      textResponse,
      loading,
      setLoadingHandler: setLoading,
      textArea,
      setTextAreaHandler: setTextArea,
      callPredictText,
    } = usePredictionContext();

    const [, setCodeState] = useRecoilState(codeState);
    const [responseError, setResponseError] = useState('');
    const [tab, setTab] = useState(0);

    const webhookUrl = useMemo(() => application?.config?.webhookUrl, [
      application,
    ]);

    const {
      openPlanPredictionExceededModal,
      validatePredictingLimitReached,
    } = useUserPlanContext();

    const testTextModel = useCallback(async () => {
      if (currentModel) {
        setLoading(true);
        const replaced = textArea.replace(/\n/g, '\\n');
        const key = predictContentKey[task] || 'text';
        try {
          if (!isApplication && (await validatePredictingLimitReached())) {
            throw new FetchError('', 401);
          }

          const payload = {
            [key]: replaced,
          };

          const response = await callPredictText({
            modelId: currentModel?.id,
            payload,
            task: task.toString(),
            headers: {
              'x-api-key': isApplication ? '' : userApiKey,
            },
          });
          setResponseError('');

          // Send the result to the webhook
          if (isApplication && webhookUrl) {
            fetch(webhookUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ payload, response }),
            }).catch(() => {});
          }
        } catch (error) {
          if (isApplication) {
            setResponseError(
              'The model is not currently available. Please reach out using a different channel or check back later. Thanks.'
            );
          } else {
            switch ((error as FetchError).statusCode) {
              case 429:
                openPlanPredictionExceededModal(isApplication);
                break;
              case 401:
                openPlanPredictionExceededModal(isApplication);
                break;
              default:
                setLoading(false);
                setResponseError((error as Error).message);
                break;
            }
          }
          setLoading(false);
        }
      }
    }, [
      currentModel,
      textArea,
      task,
      openPlanPredictionExceededModal,
      userApiKey,
      validatePredictingLimitReached,
      webhookUrl,
      isApplication,
      callPredictText,
      setLoading,
    ]);

    const handleTextArea = useCallback(
      (e) => {
        setTextArea(e.target.value);
      },
      [setTextArea]
    );

    useEffect(() => {
      setCodeState({
        modelId: currentModel?.id || '',
        text: textArea,
      });
    }, [textArea, currentModel, setCodeState]);

    useEffect(() => {
      if (!user) return;

      validatePredictingLimitReached().then((isReached) => {
        if (isReached) {
          openPlanPredictionExceededModal(isApplication);
        }
      });
    }, [
      user,
      validatePredictingLimitReached,
      openPlanPredictionExceededModal,
      isApplication,
    ]);

    const closeModalResultHandler = () => {};

    const resultPanelComponentMap: ResultPanel | undefined = useMemo(
      () =>
        textResponse && {
          [EXPERIMENT_TASK.TEXT_TRANSLATION]: (
            <TranslationResponseBox
              textResponse={textResponse}
              closeModalResultHandler={closeModalResultHandler}
            />
          ),
          [EXPERIMENT_TASK.TEXT_CLASSIFICATION]: (
            <ClassificationResponseBox textResponse={textResponse} />
          ),
          [EXPERIMENT_TASK.TEXT_NER_RESPONSE]: (
            <NerResponseBox
              textResponse={textResponse}
              closeModalResultHandler={closeModalResultHandler}
              text={textArea}
            />
          ),
          [EXPERIMENT_TASK.TEXT_QANDA]: (
            <QandAResponseBox
              textResponse={textResponse}
              closeModalResultHandler={closeModalResultHandler}
              text={textArea}
            />
          ),
          [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]: (
            <TextCustomExtractor textResponse={textResponse} text={textArea} />
          ),
          [EXPERIMENT_TASK.TEXT_LLM_CLASSIFICATION]: (
            <LLMClassificationResponseBox response={textResponse} />
          ),
        },
      // eslint-disable-next-line
      [textResponse]
    );

    const resultIsReady = !!textResponse;

    return (
      <Container
        maxWidth={resultIsReady ? 'lg' : 'sm'}
        className={classes.testWrapper}
        style={{
          gridTemplateColumns: resultIsReady ? 'repeat(2, 1fr)' : '1fr',
        }}
      >
        <Box className={classNames(classes.inputWrapper, classes.card)}>
          <Typography className={classes.title}>
            {title ?? 'Test this model'}
          </Typography>
          {!isApplication && (
            <Typography variant="body1" className={classes.subtitle}>
              Paste or enter a sample text to test this model in real-time.
            </Typography>
          )}
          {subtitle && (
            <Typography variant="body1" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}

          <TextareaAutosize
            value={textArea}
            className={classes.textArea}
            aria-label="empty textarea"
            placeholder="Paste or enter your text here..."
            onChange={handleTextArea}
          />

          <ActionButton
            size="large"
            className={classes.button}
            variant="contained"
            color="secondary"
            customColor={application?.config?.colorButton}
            onClick={testTextModel}
            loading={loading}
            disabled={!textArea}
          >
            {isApplication ? submitText || 'Submit' : 'Test this text'}
          </ActionButton>

          {responseError && (
            <Alert
              severity="error"
              onClose={() => setResponseError('')}
              className={classes.alertWrapper}
            >
              <div dangerouslySetInnerHTML={{ __html: responseError }}></div>
            </Alert>
          )}
        </Box>

        {resultIsReady && (
          <Box className={classes.card} overflow="hidden">
            <Box className={classes.text}>
              <Typography className={classes.title}>
                {isApplication && EXPERIMENT_TASK.TEXT_QANDA === task
                  ? 'Answer'
                  : 'Results'}
              </Typography>
              {!isApplication && (
                <Typography variant="body1" className={classes.subtitle}>
                  These are the results delivered by the model
                </Typography>
              )}
            </Box>

            {!isApplication && (
              <AntTabs
                value={tab}
                onChange={(e, newValue) => {
                  setTab(newValue);
                }}
                centered
              >
                <AntTab label="Preview" />
                <AntTab label="JSON" />
              </AntTabs>
            )}

            <Box className={classes.resultBody}>
              {tab === 0 ? (
                resultPanelComponentMap?.[task]
              ) : (
                <CodeBlock
                  code={textResponse}
                  language="javascript"
                  height={300}
                />
              )}
            </Box>
          </Box>
        )}
      </Container>
    );
  }
);
