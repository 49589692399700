import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  item: {
    padding: '6px 20px',
    background: '#edeeef',
    borderRadius: '6px',
    '& *': {
      color: '#4c5171',
      fontWeight: '500',
      fontSize: '14px',
    },
  },

  icon: {},

  textArea: {
    fontFamily: 'Poppins',
    boxSizing: 'border-box',
    overflow: 'auto !important',
    height: '240px !important',
    width: '100%',
    border: '1px solid #d8ddeb',
    padding: '15px',
    borderRadius: '8px',
    '&:focus': {
      border: '1px solid #afb3bd',
      outline: '1px solid #afb3bd',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  emptyMessage: {
    textAlign: 'center',
    margin: '12px 0',
    color: '#272c6a73',
    fontSize: '14px',
    fontWeight: 500,
  },
}));
