import React, { useState } from 'react';
import { Box, IconButton, TextField, Tooltip } from '@material-ui/core';
import { Text } from 'components/Text';
import useStyles from './ClassificationSettings.styles';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

interface Option {
  icon: React.ReactElement;
  onClick(): void;
  label?: string;
}

interface EditableItemProps {
  text: string;
  options: Option[];
  onChange(text: string): void;
}

export const EditableItem = ({
  text,
  onChange,
  options = [],
}: EditableItemProps) => {
  const classes = useStyles();

  const editorRef = useOnClickOutside(() => {
    if (!editing) return;
    setEditing(false);
    onChange(editableText);
  });
  const [editableText, setEditableText] = useState('');
  const [editing, setEditing] = useState(false);

  return (
    <Box
      display="flex"
      alignItems="center"
      gridGap="8px"
      className={classes.item}
    >
      {editing ? (
        <TextField
          ref={editorRef}
          variant="outlined"
          onChange={(e) => setEditableText(e.target.value)}
          size="medium"
          value={editableText}
          style={{ flexGrow: 1 }}
        />
      ) : (
        <Text
          onClick={() => {
            setEditing(true);
            setEditableText(text);
            editorRef.current?.focus();
          }}
          variant="h4"
          style={{ flexGrow: 1, wordBreak: 'break-word' }}
        >
          {text}
        </Text>
      )}

      <Box display="flex" alignItems="center" gridGap="8px" marginRight="-8px">
        {options.map(({ icon, label, onClick }, idx) => (
          <Tooltip key={idx} title={label || ''}>
            <IconButton
              key={idx}
              color="inherit"
              size="small"
              // Prevent bugs
              onClick={editing ? () => {} : onClick}
            >
              {icon}
            </IconButton>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
};
