import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  IconButton,
  Switch,
  TextareaAutosize,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { DeleteIcon, PencilOutlined as EditIcon, HelpIcon } from 'assets';
import CloseIcon from '@material-ui/icons/Close';
import { ActionButton } from 'components/ActionButton';
import { Text } from 'components/Text';
import { CategorySettings } from './CategorySettings';
import { EditableItem } from './EditableItem';
import useStyles from './ClassificationSettings.styles';
import withErrorBoundary from 'components/ErrorBoundary/withErrorBoundary';

export interface Category {
  name: string;
  description?: string;
  examples?: string[];
  negativeExamples?: string[];
}

export interface Settings {
  categories: Category[];
  is_multi_category: boolean;
  enable_explanations: boolean;
  require_high_resolution: boolean;
  instructions: string;
}

interface ClassificationSettingsProps {
  initialValues?: Settings;
  onSave(data: Settings): void;
  onChange?(data: Settings): void;
  onHasChanges?(): void;
  style?: React.CSSProperties;
  loading?: boolean;
  classificationType?: 'text' | 'image' | 'audio';
}

function useTrackedState<T>(
  initialState: T
): [T, React.Dispatch<React.SetStateAction<T>>, boolean] {
  const [state, setState] = useState(initialState);
  const [hasChanges, setHasChanges] = useState(false);

  const trackedSetState = useCallback((newState: React.SetStateAction<T>) => {
    setState(newState);
    setHasChanges(true);
  }, []);

  return [state, trackedSetState, hasChanges];
}

const ClassificationSettings = ({
  initialValues,
  onSave,
  onHasChanges,
  onChange,
  style,
  loading = false,
  classificationType = 'text',
}: ClassificationSettingsProps) => {
  const [newCategory, setNewCategory] = useState('');
  const [isMultiCategory, setIsMultiCategory] = useState(
    initialValues?.is_multi_category ?? false
  );
  const [enableExplanations, setEnableExplanations] = useState(
    initialValues?.enable_explanations ?? false
  );
  const [requireHighResolution, setRequireHighResolution] = useState(
    initialValues?.require_high_resolution ?? false
  );
  const [instructions, setInstructions] = useState(
    initialValues?.instructions ?? ''
  );
  const [categories, setCategories, categoriesChanged] = useTrackedState<
    Category[]
  >(initialValues?.categories ?? []);
  const [selectedCategoryIdx, setSelectedCategoryIdx] = useState(-1);
  const selectedCategory = categories[selectedCategoryIdx];
  const isValid = categories.length >= 2;
  const classes = useStyles();
  const data = {
    categories,
    is_multi_category: isMultiCategory,
    enable_explanations: enableExplanations,
    require_high_resolution: requireHighResolution,
    instructions,
  };

  useEffect(() => {
    if (onHasChanges) {
      if (
        isMultiCategory !== initialValues?.is_multi_category ||
        enableExplanations !== initialValues?.enable_explanations ||
        instructions !== initialValues?.instructions ||
        categoriesChanged
      ) {
        onHasChanges();
      }
    }
  }, [isMultiCategory, enableExplanations, instructions, categoriesChanged]);

  useEffect(() => {
    onChange?.(data);
  }, [onChange, data]);

  const validateCategoryName = useCallback(
    (name: string, currentIndex: number): boolean => {
      const isDuplicate = categories.some(
        (cat, index) =>
          index !== currentIndex &&
          cat.name.toLowerCase().trim() === name.toLowerCase().trim()
      );

      if (isDuplicate) {
        toast.warning('Category name already exists', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }

      return true;
    },
    [categories]
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newCatName = newCategory.trim();
    if (!newCatName) return;
    if (!validateCategoryName(newCatName, -1)) return;

    // Insert the new category at the top of the list
    setCategories((prev) => [{ name: newCatName }, ...prev]);
    setNewCategory('');
  };

  const handleRemove = (idx: number) => {
    const newCategories = categories.filter((_, i) => i !== idx);
    setCategories(newCategories);
  };

  const handleNameChange = (idx: number, text: string) => {
    const updatedData = { name: text };
    updateCategoryAtIdx(idx, updatedData);
  };

  const handleChange = (updatedData: Category) => {
    updateCategoryAtIdx(selectedCategoryIdx, updatedData, () =>
      setSelectedCategoryIdx(-1)
    );
  };
  const updateCategoryAtIdx = (
    idx: number,
    updatedData: Category,
    onSuccess?: () => void
  ) => {
    const newCatName = updatedData.name.trim();
    if (!newCatName) return;
    if (!validateCategoryName(newCatName, idx)) return;

    setCategories((prev) => {
      const arrCopy = [...prev];
      arrCopy[idx] = {
        ...arrCopy[idx],
        ...updatedData,
        name: newCatName,
      };
      return arrCopy;
    });
    onSuccess?.();
  };

  const handleSave = () => {
    if (!isValid) return;

    onSave(data);
  };

  return (
    <>
      <Box style={style}>
        <Box
          display="flex"
          flexDirection="column"
          gridGap="14px"
          textAlign="left"
          component="form"
          onSubmit={handleSubmit}
        >
          <Text variant="h4">Classification Categories</Text>

          <Box display="flex" gridGap="8px">
            <TextField
              variant="outlined"
              placeholder="Enter category name"
              onChange={(e) => setNewCategory(e.target.value)}
              size="medium"
              value={newCategory}
              style={{ flexGrow: 1 }}
            />
            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              size="medium"
            >
              Add
            </Button>
          </Box>

          {!categories.length && (
            <Text className={classes.emptyMessage}>
              No categories yet. Add one to begin.
            </Text>
          )}

          <Box
            display="flex"
            flexDirection="column"
            gridGap="12px"
            textAlign="left"
          >
            {categories.map((category, idx) => (
              <EditableItem
                key={category.name}
                text={category.name}
                onChange={(text) => handleNameChange(idx, text)}
                options={[
                  {
                    icon: (
                      <EditIcon
                        className={classes.icon}
                        width={21}
                        height={21}
                      />
                    ),
                    onClick: () => setSelectedCategoryIdx(idx),
                    label: 'Edit',
                  },
                  {
                    icon: (
                      <DeleteIcon
                        className={classes.icon}
                        width={28}
                        height={28}
                      />
                    ),
                    onClick: () => handleRemove(idx),
                    label: 'Remove',
                  },
                ]}
              />
            ))}
          </Box>

          <div></div>

          <FormControlLabel
            control={
              <Switch
                checked={isMultiCategory}
                onChange={() => setIsMultiCategory(!isMultiCategory)}
                color="primary"
              />
            }
            label="Enable Multi-Category Classification"
          />

          <FormControlLabel
            control={
              <Switch
                checked={enableExplanations}
                onChange={() => setEnableExplanations(!enableExplanations)}
                color="primary"
              />
            }
            label="Provide Explanations for the Results"
          />

          {classificationType === 'image' && (
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={requireHighResolution}
                    onChange={() =>
                      setRequireHighResolution(!requireHighResolution)
                    }
                    color="primary"
                  />
                }
                label="Require High Resolution"
              />
              <Box marginLeft="-8px" marginTop="4px">
                <Tooltip title="Select this option to prevent images from being resized to the default 512x512 resolution used for vision classification tasks. Enable this option only if your classification problem requires analyzing very small details.">
                  <HelpIcon width={22} height={22} />
                </Tooltip>
              </Box>
            </Box>
          )}

          <div></div>

          <Text variant="h4">Classification Context</Text>

          <TextareaAutosize
            value={instructions}
            className={classes.textArea}
            placeholder="Describe the classification task you want to perform. For example: 'Classify customer reviews into positive, negative, or neutral categories.' or 'Categorize support tickets by urgency level.'"
            onChange={(e) => setInstructions(e.target.value)}
          />

          <div></div>

          <Box display="flex" justifyContent="center">
            <ActionButton
              size="large"
              variant="contained"
              color="secondary"
              onClick={handleSave}
              disabled={!isValid}
              style={{ width: 'max-content' }}
              loading={loading}
            >
              Save Settings
            </ActionButton>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={!!selectedCategory}
        onClose={() => setSelectedCategoryIdx(-1)}
        maxWidth="md"
      >
        {selectedCategory && (
          <Box position="relative">
            <IconButton
              onClick={() => setSelectedCategoryIdx(-1)}
              style={{
                position: 'absolute',
                top: 6,
                right: 6,
                zIndex: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
            <CategorySettings
              initialValues={selectedCategory}
              onSave={handleChange}
              style={{ maxWidth: '100%', width: '500px' }}
              classificationType={classificationType}
            />
          </Box>
        )}
      </Dialog>
    </>
  );
};

// Custom error handler function (optional)
const handleError = (error: Error, errorInfo: React.ErrorInfo) => {
  console.log('Error caught by boundary:', error);
  console.log('Error info:', errorInfo);
};

// Custom fallback UI
const customFallback = (
  <div>
    <h1>Oops! Something went wrong.</h1>
    <p>
      It looks like we encountered an issue. Please{' '}
      <a href="#" onClick={() => window.location.reload()}>
        restart the page
      </a>
      .
    </p>
  </div>
);

export default withErrorBoundary(
  ClassificationSettings,
  customFallback,
  handleError
);
